import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./style.css";  // Import the CSS file

const Tickets = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null); // Store selected package
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [redirectUrl, setRedirectUrl] = useState("");
  const [showModal, setShowModal] = useState(false);  // Modal visibility state

  // Fetch packages from the API
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(
          "https://eventflowbackend.lifehisto.com/api/payment/packages"
        );
        setPackages(response.data.packages);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };
    fetchPackages();
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };

  // Handle package selection
  const handlePackageSelect = (pkg) => {
    setSelectedPackage(pkg);
  };

  // Handle form submission for payment
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedPackage) {
      alert("Please select a package.");
      return;
    }

    try {
      const response = await axios.post(
        "https://eventflowbackend.lifehisto.com/api/payment/init",
        {
          user_name: userDetails.name,
          user_email: userDetails.email,
          user_phone: userDetails.phone,
          package_uuid: selectedPackage.uuid,
        }
      );

      setRedirectUrl(response.data.redirectUrl);
      setShowModal(true);  // Show the modal when payment is initiated
    } catch (error) {
      console.error("Error initiating payment:", error);
      alert("Failed to initiate payment. Please try again.");
    }
  };

  // Handle modal OK click to redirect
  const handleRedirect = () => {
    window.location.href = redirectUrl;  // Redirect to the payment URL
  };

  // Render the package list or payment form based on selection
  return (
    <>
      <Navbar />
      <div className="ticket-container">
        {!selectedPackage ? (
          <>
          <h1>Corporate Night Life Cocktail Party</h1>
            <h1>Select Your Package</h1>
            <div className="packages-grid">
              {packages.map((pkg) => (
                <div
                  key={pkg.uuid}
                  className="package-card"
                >
                  <h3>{pkg.name}</h3>
                  <p>{pkg.description}</p>
                  <button onClick={() => handlePackageSelect(pkg)}>
                    <strong>Price: ₹{pkg.price}</strong>
                  </button>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="selected-package-details">
              <h1>Proceed to Payment</h1>
              <div className="selected-package-details">
                <h3>Selected Package: {selectedPackage.name}</h3>
                <p>{selectedPackage.description}</p>
                <p>
                  <strong>Price: ₹{selectedPackage.price}</strong>
                </p>
              </div>
              <form onSubmit={handleSubmit} className="payment-form">
                <div className="form-field">
                  <label>
                    Name:
                    <input
                      type="text"
                      name="name"
                      value={userDetails.name}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
                <div className="form-field">
                  <label>
                    Email:
                    <input
                      type="email"
                      name="email"
                      value={userDetails.email}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
                <div className="form-field">
                  <label>
                    Phone:
                    <input
                      type="tel"
                      name="phone"
                      value={userDetails.phone}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
                <button type="submit">Proceed to Payment</button>
              </form>
            </div>
          </>
        )}
      </div>

      {/* Modal for payment confirmation */}
      {showModal && (
        <div className="payment-modal">
          <div className="modal-content">
            <h2>Payment Initiated</h2>
            <p>
              Click <strong>OK</strong> to complete your payment.
            </p>
            <button onClick={handleRedirect}>OK</button>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default Tickets;
