import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";

const ContactUs = () => {
  return (
    <>
      <Navbar />
      
      <div className="contact" id="contact">
        <div className="max-width">
          <h2 className="title">Get in Touch</h2>
          <div className="contact-content">
            <div className="column left">
              <div className="icons">
                <div className="row">
                  <PersonIcon className="icon" />
                  <div className="info">
                    <div className="head">Name</div>
                    <div className="sub-title">LifeHisto</div>
                  </div>
                </div>
                <div className="row">
                  <LocationOnIcon className="icon" />
                  <div className="info">
                    <div className="head">Address</div>
                    <div className="sub-title">
                      2-48/5/6, Gachibowli Rd, Opp. RTTC, Telecom Nagar
                      Extension, Gachibowli, Hyderabad, Telangana 500032
                    </div>
                  </div>
                </div>
                <div className="row">
                  <EmailIcon className="icon" />
                  <div className="info">
                    <div className="head">Email</div>
                    <div className="sub-title">lifehisto@gmail.com</div>
                  </div>
                </div>
                <div className="row">
                  <CallIcon className="icon" />
                  <div className="info">
                    <div className="head">Phone</div>
                    <div className="sub-title">+91 9293225555</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </>
  );
};

export default ContactUs;
