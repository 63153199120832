import React from "react";
import "./Navbar.css";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src="./images/lifehistologo.png" alt="Logo 1" className="logo" />
      </div>
      <div className="navbar-logo">
        <img src="./images/EVENTFLOWLOGO.png" alt="Logo 2" className="logo" />
      </div>
    </nav>
  );
};

export default Navbar;
