import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import necessary components
import Tickets from './components/Tickets';
import PrivacyPolicy from './components/T&C pages/PrivacyPolicy';
import TermsConditions from './components/T&C pages/TermsConditons';
import RefundCancellationPolicy from './components/T&C pages/RefundCancellationPolicy';
import ContactUs from './components/ContactUs';



function App() {
  return (
    <React.Fragment>
    <Routes>
        <Route path="/" element={<Tickets />} />

        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="/refund" element={<RefundCancellationPolicy />} />
        <Route path="/contact" element={<ContactUs />} />


        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/contact" element={<Contact />} /> */}

        </Routes>
    </React.Fragment>
  );
}

export default App;
